@import "styles/config";
$accentLight: #c0ff45;
$accentDark: #00b254;
$baseWhite: #ffffff;
$baseBlack: #222222;
$background-gray: #f4f4f4;

//global variables referenced in other scss modules
$background: #f4f4f4;
$background-bar: #d1cfc5;

/// START LOCATION PAGE EDITS

// changes to nav tabs to allow for unique styling on location (home) page
.rc-tabs-bar {
  border-bottom: 2px solid $accentDark !important;
  background-color: $accentDark;
  padding: 1rem;
}

.rc-tabs-tab {
  text-align: center;
  margin: 0 4.375rem;
  right: 2.813rem;
}

%location {
  .location {
    &-container {
      &--header {
        padding: 1rem 0;
        box-shadow: 0rem 0.25rem 0.25rem rgba(54, 40, 40, 0.1) !important;
        .body-title {
          margin-left: 1rem;
        }
        // Add some space in the tabs
        & .tabs {
          &-container {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
          }
        }
      }
    }
  }
}

.order-button {
  background: $accentDark !important;
  box-shadow: 0px 2px 4px #d8d8d8 !important;
  border-radius: 99px !important;
  color: white !important;
  border-color: $accentDark !important;
}

%locationCard {
  @include elevation(2);
}

//// END LOCATION PAGE EDITS

//// START MENU PAGE EDITS

// restricts the size of the locationInfo Banner on menu page
%locationInfo {
  max-width: 30rem !important;
  .locationInfo-body {
    line-height: 1.5;
  }
}

%group {
  .group {
    &-info {
      width: 95%;
      margin-left: 0;
      padding-bottom: 4.375rem;
      border-bottom: 2px solid $baseBlack;
      transform: translateX(2vw);
    }

    &-info-container {
      padding-top: 2.5rem !important;
      padding-bottom: 2rem !important;
    }
  }
}

//// END MENU PAGE EDITS

//// START ITEM-DETAIL DRAWER EDITS

%itemDetails {
  .itemDetails {
    // formats the back button to proper height / size
    &-back {
      box-shadow: none;
      & > div {
        width: 2rem;
        height: 2rem;
      }
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

// Fit item details header closer to figma design
%itemDetailsHeader {
  padding: 2rem 0 !important;
  .itemDetailsHeader {
    &-content {
      div:last-child {
        div:last-child {
          margin-bottom: 1rem !important;
        }
        span:last-child {
          margin-bottom: 0 !important;
        }
      }

      &-diet {
        margin: 0 1.5rem !important;
      }
    }
  }
}

// special styling for navbar in itemDetails
%itemDetails-tabs {
  .itemDetails-tabs {
    &-nav-container {
      .nav {
        background-color: $accentDark !important;
        .nav-item {
          .item-button {
            padding: 0.5rem 1rem;
          }
          &[data-active="1"] {
            .item-button {
              background-color: $accentLight !important;
            }
          }
        }
      }
    }
  }
}

%quantitySelector {
  padding-bottom: 0 !important;
}

/// END ITEMDETAIL DRAWER EDITS

//// START CART DRAWER EDITS

%orderItem {
  border-bottom: 2px solid #f4f4f4;
}

%upsellItem {
  box-shadow: 0px 0px 7px 2px #ececec !important;
  .upsellItem {
    &-actions {
      justify-content: flex-start !important;
    }
  }
}

/// END CART DRAWER EDITS

// START CHECKOUT EDITS

// Center tip selector
.tip {
  & > span {
    display: flex !important;
    align-items: center !important;

    .tip-label {
      margin-bottom: 0 !important;
    }

    .tip-group {
      flex-grow: 1 !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
}

%tipsContainer {
  .tipsContainer {
    &-wrapper {
      &--singleTip {
        border: 1px solid $accentDark !important;
        & > button[aria-pressed="true"] {
          span {
            background-color: $accentDark !important;
          }
        }
      }

      &--customAmountSelected {
        color: white !important;
      }
    }
  }
}

/// END CHECKOUT EDITS

// START ORDER CONFIRMATION EDITS

%confirmItem {
  @include elevation(2);
}

.confirm {
  padding: 0 3rem;
}

/// END ORDER CONFIRMATION EDITS

// left aligns the welcome atop sign in drawer.
%loyaltyHeader {
  padding: 0 0 1rem 0 !important;
  .loyaltyHeader {
    &-description {
      &-text {
        text-align: left;
        margin-left: 1rem;
        max-width: 20rem;
      }
    }
  }
}

.modifierItem {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

%modifierItem {
  .modifierItem {
    &-container {
      padding: 0.5rem;
      line-height: 3rem !important;
    }
    &-selectors {
      &-quantityBtn {
        width: 3rem !important;
        height: 3rem !important;
        &--radio input[type="checkbox"] + label {
          width: 3rem !important;
          height: 3rem !important;
          border-radius: 3rem;
        }
      }
    }
  }
}

// Profile Section Header Overrride
.orderHistory-content-date {
  color: $baseBlack;
}
